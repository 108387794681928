import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { breadcrumbs } from "shared/helpers/schema";

import Page from "../../../components/Page";
import Hero from "../../../components/Hero";
import Tile from "../../../components/Tile";
import Card from "../../../components/Card";
import Kranicon from "../../../components/Kranicon";
import Container from "../../../components/Container";
import OnOfficeFrame from "../../../components/OnOfficeFrame";
import PageBlock from "../../../components/PageBlock";
import { Link } from "gatsby";

const Angebote = ({ data }) => (
  <Page
    header={
      <Hero
        fluid={data.propertiesImage.fluid}
        icon={<Kranicon icon="suche" />}
        title="Immobilien aus Weimar und Umgebung"
        intro="Entdecken Sie unsere aktuellen Immobilien-Angebote aus Weimar und Umgebung"
      />
    }
  >
    <Helmet
      title="Immobilien Finden"
      meta={[
        {
          name: "description",
          content:
            "Finden Sie bei Kranich Immobilien die perfekte Immobilie in Weimar und Umgebung.",
        },
      ]}
      script={[
        breadcrumbs({
          crumbs: [
            {
              name: "Suchen",
              link: "/angebote/",
              position: 1,
            },
          ],
          siteUrl: data.site.siteMetadata.siteUrl,
          siteTitle: data.site.siteMetadata.title,
        }),
      ]}
    />
    <PageBlock>
      <Tile>
        <Card>
          <Container spaced>
            <h2>Unser Angebot an Immobilien in Weimar und Umgebung.</h2>
            <p>Als zuverlässiger und exklusiver <Link to="/immobilienmakler-weimar/">Immobilienmakler für Weimar</Link> haben wir viele schönen Objekte aus Weimar und Umgebung für Sie im Angebot. Vom Einfamilienhaus bis hin zum Bauernhof können Sie bei uns so einiges entdecken. </p>
            <OnOfficeFrame />
          </Container>
        </Card>
      </Tile>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    propertiesImage: imageSharp(
      fluid: { originalName: { regex: "/properties/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export default Angebote;
